<template>
    <div
        id="operating_expenses_widget"
        class="expenses_widget panel panel-default panel-page"
    >
        <div class="panel-heading align-items-center justify-space-between flex flex-wrap">
            <span class="chart-title flex-shrink-0"> Top Operating Expense Categories</span>
            <div
                v-if="withActualsLabel"
                class="pull-right toggle"
            >
                <span class="text-muted">
                    <small>Actuals to end of {{ actualsToLabel }}</small>
                </span>
                <el-radio-group
                    v-model="options.basis"
                    size="small"
                    @change="fetchOperatingExpenses()"
                >
                    <el-radio-button label="cash">
                        Cash
                    </el-radio-button>
                    <el-radio-button label="accrual">
                        Accrual
                    </el-radio-button>
                </el-radio-group>
            </div>
        </div>

        <div class="panel-body">
            <div
                v-if="loading"
                class="loader text-center"
            >
                <img
                    class="inline"
                    alt=""
                    src="/img/loading.gif"
                >
            </div>
            <div
                v-else
                ref="chart_graph"
                class="chart"
            />
            <div v-if="hasFailed">
                <p class="text-muted text-center">
                    Hold tight, we're crunching those numbers for you
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { routes, http } from "Figured/Assets/Modules";

export default {
    name: "DashboardOperatingExpenses",

    props: {
        year: {
            type: Number|String
        },
        budgetType: {
            type: String
        },
        actualsToLabel: {
            type: String,
        },
        actualsToHorizon: {
            type: String,
        },
        isApiData: {
            type: Boolean,
            default: true
        },
        chartData: {
            type: Object
        },
        withExportOptions: {
            type: Boolean,
            default: true
        },
        withActualsLabel: {
            type: Boolean,
            default: true
        },
    },

    data() {
        return {
            loading: false,
            hasFailed: false,
            columnColor: ["#f4a87d", "#c593d6", "#3bad60"],
            options: {
                year: this.year,
                type: this.budgetType,
                actuals_to: this.actualsToHorizon,
                basis: "accrual"
            },
            chartSettings: {
                chart: {
                    type: "column"
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: "&nbsp;",
                    useHTML:true,
                    margin:20,
                },
                xAxis: {
                    type: "category",
                    crosshair: true,
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: lang.trans("units.$"),
                        rotation: 0
                    }
                },
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        borderRadius: 1,
                        animation: false
                    },
                },
                legend: {
                    enabled: true
                },
                tooltip: {
                    pointFormat: lang.trans("units.$") + "<b>{point.y:0f}</b>"
                },
                series: [],
                exporting:{
                    enabled: this.withExportOptions,
                    chartOptions:{
                        title: {
                            text: "Top Operating Expenses",
                        },
                        plotOptions: {
                            series: {
                                dataLabels: {
                                    enabled: true,
                                    format: "{point.y:,.0f}"
                                }
                            }
                        }
                    }
                }
            }
        }
    },

    mounted() {
        if (this.isApiData) {
            this.fetchOperatingExpenses();
        } else {
            this.loadChart(this.chartData)
        }
    },

    methods: {
        fetchOperatingExpenses() {
            this.loading = true;
            let route = routes.get("service.reports.dashboard.operating_expenses")
            http.get(route, {
                params: this.options
            }).then((response) => {
                this.loading = false;
                this.loadChart(response.data);
            }).catch((error) => {
                this.hasFailed = true;
            }).finally(() => {
                this.loading = false;
            })
        },
        loadChart(data) {
            this.chartSettings.xAxis.categories = data.categories;
            this.chartSettings.series = data.data.map((item, key) => {
                return {
                    name: item.year,
                    data: item.data,
                    color: this.columnColor[key]
                }
            });

            if (this.isApiData) {
                setTimeout(() => {
                    new Highcharts.chart(this.$refs.chart_graph, this.chartSettings);
                }, 100);
            } else {
                //Load the highchart immediately for PDF Export
                new Highcharts.chart(this.$refs.chart_graph, this.chartSettings);
            }
        }
    }
}
</script>
<style lang="scss">
    .expenses_widget {
        border: 1px solid #ddd;
        box-shadow: none;

        .toggle {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
    }
</style>
