<template>
    <div
        id="closing_cash_position_widget"
        class="panel panel-default panel-page"
    >
        <div class="panel-heading align-items-center justify-space-between flex flex-wrap">
            <span class="chart-title flex-shrink-0"> {{ title }}</span>
            <div
                v-if="withActualsLabel"
                class="pull-right toggle"
            >
                <span class="text-muted">
                    <small>Actuals to end of {{ actualsToLabel }}</small>
                </span>
            </div>
        </div>

        <div class="panel-body">
            <div
                v-if="loading"
                class="loader text-center"
            >
                <img
                    class="inline"
                    alt=""
                    src="/img/loading.gif"
                >
            </div>
            <div
                v-else
                ref="chart_graph"
                class="chart"
            />
            <div v-if="hasFailed">
                <p class="text-muted text-center">
                    Hold tight, we're crunching those numbers for you
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { routes, http } from "Figured/Assets/Modules";
import {number_format} from "Assets/Modules/math";


export default {
    name: "ClosingCashPosition",

    props: {
        year: {
            type: Number|String,
        },
        actualsToLabel: {
            type: String,
        },
        actualsToHorizon: {
            type: String,
        },
        showFullYearForecast: {
            type: Boolean,
            default: false,
        },
        comparisonGraphName: {
            type: String,
        },
        isApiData: {
            type: Boolean,
            default: true
        },
        chartData: {
            type: Object
        },
        withExportOptions: {
            type: Boolean,
            default: true
        },
        withActualsLabel: {
            type: Boolean,
            default: true
        },
        withFeatures: {
            type: String,   // comma separated list of features. If you can make it work as Array, feel free to change it
            required: false,
        },
    },

    data() {
        return {
            loading: false,
            hasFailed: false,
        }
    },

    mounted() {
        this.chartSettings.exporting.chartOptions.title.text = this.title;
        if (this.isApiData) {
            this.fetchClosingCashPosition();
        } else {
            this.loadChart(this.chartData);
        }
    },

    computed: {
        title() {
            return this.showFullYearForecast
                ? this.comparisonGraphName + " vs Forecast Closing Cash Position"
                : this.comparisonGraphName + " vs Actual + Forecast Closing Cash Position";
        },
        options() {
            return {
                year:  this.year,
                actualsTo: this.actualsToHorizon,
                refresh: 0,
                type: "dashboard_cash_position",
                features: this.features,
            };
        },
        features() {
            return this.withFeatures ? this.withFeatures.split(",") : null;
        },
        chartSettings() {
            return {
                chart: {
                    type: "area"
                },
                title: {
                    text: "&nbsp;",
                    useHTML: true,
                    margin: 20
                },
                legend: {
                    layout: "horizontal",
                    align: "center",
                    verticalAlign: "bottom",
                    borderWidth: 0,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || "#FFFFFF"
                },
                xAxis: {},
                yAxis: {
                    title: {
                        text: lang.trans("units.$"),
                        rotation: 0
                    }
                },
                tooltip: {
                    shared: true,
                    valuePrefix: lang.trans("units.$"),
                    valueDecimals: 2,
                    useHTML: true,
                    className: "highcharts-tooltip-closing-cash-position",
                    padding: 0,
                    borderColor: "transparent",
                    backgroundColor: "transparent",
                    shadow: false,
                    formatter: function () {
                        /**
                         * We can't (easily) use Vue to render this tooltip as Highcharts itself and callbacks are out of scope of Vue.
                         */
                        const symbols = {
                            "circle":        "●",
                            "diamond":       "♦",
                            "square":        "■",
                            "triangle":      "▲",
                            "triangle-down": "▼",
                        };

                        let tooltipContent  = "";
                        let tooltipMessages = "";

                        for (let idx in this.points) {
                            let point       = this.points[idx];
                            let valuePrefix = point.series.tooltipOptions ? point.series.tooltipOptions.valuePrefix : "";
                            let symbol      = symbols[point.series.symbol];

                            tooltipContent += "<div class=\"point\">" +
                                "<span class=\"icon\" style=\"color:" + point.color + "\">" + symbol + "</span>" +
                                point.series.name + ": " +
                                "<span class=\"value\"> " + valuePrefix + "" + number_format(point.y, 2) + "</span>" +
                                "</div>";

                            if (point.point.custom && point.point.custom.message) {
                                for (let messageIdx in point.point.custom.message) {
                                    tooltipMessages += "<div class=\"tooltip-message " + point.point.custom.message[messageIdx].type + "\">" +
                                        "<div class=\"title\">" + point.point.custom.message[messageIdx].title + "</div>" +
                                        "<div class=\"content\">" + point.point.custom.message[messageIdx].content + "</div>" +
                                        "</div>";
                                }
                            }
                        }

                        return "<div class=\"tooltip-container\">" +
                            "<div class=\"tooltip-data\">" +
                            "<div class=\"title\">" + this.x + "</div>" +
                            "<div class=\"content\">" + tooltipContent + "</div>" +
                            "</div>" +
                            tooltipMessages +
                            "</div>";
                    },
                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: false,
                        },
                        animation: false
                    }
                },
                series: [
                    {
                        name: "Actual + Forecast Closing Cash Position",
                        fillColor: "rgba(255, 255, 255, 0)",
                        fillOpacity: 0.1,
                        color: "black",
                        zIndex: 2,
                        marker: {
                            fillColor: "#FFFFFF",
                            lineWidth: 1,
                            radius: 3,
                            symbol: "circle",
                            lineColor: "black"
                        },
                        stickyTracking: false,
                        events: {
                            legendItemClick: function () {
                                return false;
                            },
                            click: function (event) {
                                if (event.point.custom?.message) {
                                    const firstUrl = event.point.custom?.message.find((message) => { return !!message.url })?.url;

                                    if (firstUrl) {
                                        window.location.href = firstUrl;
                                    }
                                }
                            },
                        },
                    },
                    {
                        name: "Annual Plan Closing Cash Position",
                        fillColor: "rgba(255, 255, 255, 0)",
                        fillOpacity: 0.1,
                        color: "#409EFF",
                        zIndex: 1,
                        marker: {
                            fillColor: "#FFFFFF",
                            lineWidth: 1,
                            radius: 3,
                            symbol: "circle",
                            lineColor: "#409EFF"
                        },
                        events: {
                            legendItemClick: function () {
                                return false;
                            }
                        }
                    },
                    {
                        name: "Overdraft Facility Limit",
                        fillColor: "rgba(255, 255, 255, 0)",
                        fillOpacity: 0.1,
                        color: "#ff0000",
                        zIndex: 1,
                        marker: {
                            fillColor: "#FFFFFF",
                            lineWidth: 1,
                            radius: 3,
                            symbol: "circle",
                            lineColor: "#ff0000"
                        },
                        events: {
                            legendItemClick: function () {
                                return false;
                            }
                        }
                    },
                ],
                exporting:{
                    enabled: this.withExportOptions,
                    chartOptions:{
                        title: {
                            text: "",
                        },
                        plotOptions: {
                            series: {
                                dataLabels: {
                                    enabled: true,
                                    format: "{point.y:,.0f}"
                                }
                            }
                        }
                    }
                }
            }
        },
    },

    methods: {
        fetchClosingCashPosition() {
            this.loading = true;
            let route = routes.get("service.reports.dashboard.data")
            http.get(route, {
                params: this.options
            }).then((response) => {
                this.loading = false;
                this.loadChart(response.data);
            }).catch((error) => {
                this.hasFailed = true;
            }).finally(() => {
                this.loading = false;
            })
        },
        loadChart(data) {
            this.chartSettings.xAxis.categories = data.report.labels;
            this.chartSettings.series[0].data = data.report.data[0];
            if (typeof data.report.data[1] !== "undefined" && data.with_comparison_data) {
                this.chartSettings.series[1].data = data.report.data[1];
            }

            if (data.report.data.overdraft) {
                this.chartSettings.series[2].data = data.report.data.overdraft;
            } else {
                // remove overdraft information if it is not available
                this.chartSettings.series.splice(2, 1);
            }

            if (this.isApiData) {
                setTimeout(() => {
                    new Highcharts.chart(this.$refs.chart_graph, this.chartSettings);
                }, 100);
            } else {
                //Load the highchart immediately for PDF Export
                new Highcharts.chart(this.$refs.chart_graph, this.chartSettings);
            }
        }
    }

}
</script>

<style lang="scss">
@import 'src/Figured/Assets/Themes/default/_colours.scss';
@import 'src/Figured/Packages/Features/Lending/Assets/lending-colours.scss';
    #closing_cash_position_widget {
        border: 1px solid #ddd;
        box-shadow: none;
        svg.highcharts-root {
            .highcharts-series-hover {
                image.highcharts-point-hover {
                    cursor: pointer;
                }
            }
        }
        .highcharts-tooltip-closing-cash-position {
            > span {
                .tooltip-container {
                    max-width: 420px;
                    font-family: Roboto, Helvetica, Arial, sans-serif !important;
                    color: $fg-dark-grey;
                    border: 1px solid $fg-muted-grey;
                    background-color: $fg-white;
                    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.3);
                    border-radius: 4px;
                    white-space: normal;
                    font-size: 14px;
                    line-height: 22px;
                    .tooltip-data {
                        padding: 15px;
                        .title {
                            font-size: 12px;
                            line-height: 11px;
                            padding-bottom: 5px;
                        }

                        .content {
                            .icon {
                                padding-right: 10px;
                            }

                            .point {
                                white-space: nowrap;
                                .value {
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                    .tooltip-message {
                        padding: 15px;
                        line-height: 16px;
                        // @see DashboardWidget Vue component
                        &.lending {
                            background-color: $lending-orange-background;
                            color: $lending-orange-text;
                        }

                        .title {
                            font-weight: bold;
                            padding-bottom: 5px;
                        }
                    }
                }
            }
        }
    }
</style>
