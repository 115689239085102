<template>
    <div
        id="variance_by_category"
        class="panel panel-default panel-page"
    >
        <div class="panel-heading align-items-center justify-space-between flex flex-wrap">
            <span class="chart-title flex-shrink-0"> {{ title }}</span>
            <div
                v-if="withActualsLabel"
                class="pull-right toggle"
            >
                <span class="text-muted">
                    <small>Actuals to end of {{ actualsToLabel }}</small>
                </span>
                <el-radio-group
                    v-model="options.basis"
                    size="small"
                    @change="fetchVarianceByCategory()"
                >
                    <el-radio-button label="cash">
                        Cash
                    </el-radio-button>
                    <el-radio-button label="accrual">
                        Accrual
                    </el-radio-button>
                </el-radio-group>
            </div>
        </div>

        <div class="panel-body">
            <div
                v-if="loading"
                class="loader text-center"
            >
                <img
                    class="inline"
                    alt=""
                    src="/img/loading.gif"
                >
            </div>
            <div
                v-else
                ref="chart_graph"
                class="chart"
            />
            <div v-if="hasFailed">
                <p class="text-muted text-center">
                    Hold tight, we're crunching those numbers for you
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { routes, http } from "Figured/Assets/Modules";
import { number_format } from "Figured/Assets/Modules/math.js";

export default {
    name: "VarianceByCategory",

    props: {
        year: {
            type: Number|String
        },
        actualsToLabel: {
            type: String,
        },
        actualsToHorizon: {
            type: String,
        },
        comparisonGraphName: {
            type: String,
        },
        isApiData: {
            type: Boolean,
            default: true
        },
        chartData: {
            type: Object
        },
        withExportOptions: {
            type: Boolean,
            default: true
        },
        withActualsLabel: {
            type: Boolean,
            default: true
        },
    },

    data() {
        return {
            loading: false,
            hasFailed: false,
            options: {
                year:  this.year,
                actualsTo: this.actualsToHorizon,
                refresh: 0,
                type: "dashboard_waterfall",
                basis: "cash"
            },
            chartSettings: {
                chart: {
                    type: "waterfall"
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: "&nbsp;",
                    useHTML:true,
                    margin:20,
                },
                xAxis: {
                    type: "category"
                },
                yAxis: {
                    title: {
                        text: lang.trans("units.$"),
                        rotation: 0
                    }
                },
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        borderRadius: 1,
                        dataLabels: {
                            enabled: true,
                            inside: false,
                            style: {
                                fontSize: 10,
                                color: "red"
                            }
                        },
                        animation: false
                    }
                },
                legend: {
                    enabled: false
                },
                tooltip: {
                    pointFormat: lang.trans("units.$") + "<b>{point.y:0f}</b>"
                },
                series: [{
                    upColor: "#3bad60",
                    color: "#f7aa48",
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            const dividend = this.y;
                            const divisor = 1000;

                            // If dividend is less than divisor, still display it but without the "k" suffix.
                            if (divisor > Math.abs(dividend)) {
                                return number_format(dividend, 0, false);
                            }

                            return Highcharts.numberFormat(dividend / divisor, 0, ",") + "k";
                        },
                        style: {
                            color: "#606060",
                            fontWeight: "bold"
                        }
                    },
                    pointPadding: 0
                }],
                exporting:{
                    enabled: this.withExportOptions,
                    chartOptions:{
                        title: {
                            text: ""
                        }
                    },
                    plotOptions: {
                        series: {
                            dataLabels: {
                                enabled: true,
                                format: "{point.y:,.0f}"
                            }
                        }
                    }
                }
            }
        }
    },

    mounted() {
        this.chartSettings.exporting.chartOptions.title.text = this.title;
        if (this.isApiData) {
            this.fetchVarianceByCategory();
        } else {
            this.loadChart(this.chartData);
        }
    },

    computed: {
        onRollingPlan() {
            return window.Figured.onRollingPlan;
        },
        title() {
            return this.comparisonGraphName + " vs Actuals + Forecast: Variance by Category"
        }
    },

    methods: {
        fetchVarianceByCategory() {
            this.loading = true;
            let route = routes.get("service.reports.dashboard.data")
            http.get(route, {
                params: this.options
            }).then((response) => {
                this.loading = false;
                this.loadChart(response.data);
            }).catch((error) => {
                this.hasFailed = true;
            }).finally(() => {
                this.loading = false;
            })
        },
        loadChart(data) {
            this.chartSettings.xAxis.categories = data.report.labels;
            this.chartSettings.series[0].data = data.report.data[0];
            if (typeof data.report.data[1] !== "undefined" && data.with_comparison_data) {
                this.chartSettings.series[1].data = data.report.data[1];
            }

            if (data.report.data.overdraft) {
                this.chartSettings.series[2].data = data.report.data.overdraft;
            } else {
                // remove overdraft information if it is not available
                this.chartSettings.series.splice(2, 1);
            }

            if (this.isApiData) {
                setTimeout(() => {
                    new Highcharts.chart(this.$refs.chart_graph, this.chartSettings);
                }, 100);
            } else {
                //Load the highchart immediately for PDF Export
                new Highcharts.chart(this.$refs.chart_graph, this.chartSettings);
            }
        }
    }

}
</script>

<style lang="scss">
    #variance_by_category {
        border: 1px solid #ddd;
        box-shadow: none;
        
        .toggle {
            display: flex;
            flex-direction: column;      
            align-items: flex-end;
        }
    }
</style>
